import React from 'react';
import { analytics } from './useLoadSegment';
import { ID } from '@segment/analytics-next';

export default function useAnonymousId() {
  const [anonymousId, setAnonymousId] = React.useState<ID | null>(null);
  React.useEffect(() => {
    const getAnonymousId = async () => {
      const id = (await analytics.user()).anonymousId();
      setAnonymousId(id);
    };
    if (!anonymousId) getAnonymousId();
  }, [anonymousId]);

  return anonymousId;
}
